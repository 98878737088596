<template>
	<v-list>
		<template v-for="(item, index) in cargoBay.items">
			<v-divider :key="index" v-if="index > 0" />

			<v-list-item :key="item.name">
				<v-list-item-content>
					<v-list-item-title>{{ item.quantity }} {{ item.name }}</v-list-item-title>
				</v-list-item-content>

				<v-list-item-action v-if="linkedCargoBay">
					<v-btn text icon color="green" @click="moveItemToLinkedCargoBay(item)">
						<v-icon>attach_money</v-icon>
					</v-btn>
				</v-list-item-action>
			</v-list-item>
		</template>
	</v-list>
</template>

<script>
export default {
	name: 'CargoListPanel',
	props: {
		entity: null,
		linkedCargoBay: null
	},
	computed: {
		cargoBay() {
			return this.entity.cargoBay;
		}
	},
	methods: {
		moveItemToLinkedCargoBay(item) {
			item = item.clone();
			this.cargoBay.removeItem(item);
			this.linkedCargoBay.addItem(item);
		}
	}
}
</script>