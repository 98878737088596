<template>
	<v-list>
		<template v-for="(ship, index) in entity.dock.dockedShips">
			<v-divider :key="index" v-if="index > 0" />

			<v-list-item :key="ship.name">
				<v-list-item-content>
					<v-list-item-title>{{ ship.name }}</v-list-item-title>
				</v-list-item-content>

				<v-list-item-action>
					<v-btn text icon color="green" @click="openShipView(ship)">
						<v-icon>info</v-icon>
					</v-btn>
				</v-list-item-action>
			</v-list-item>
		</template>
		</v-list>
</template>

<script>
export default {
	name: 'DockedListPanel',
	props: {
		entity: null
	},
	computed: {
		
	},
	methods: {
		openShipView(ship) {
			window.mainGameHUD.shipInfo = ship;
		}
	}
}
</script>