<template>
	<v-card>
		<v-card-title primary-title>
			<div class="headline">Vital Systems</div>
		</v-card-title>

		<v-list dense>
			<v-list-item>Oxygen: {{ oxygen }}%
                <span style="color: green; margin-left: 0.2em;" v-if="airFilterLeft"> (Filters left: {{ airFilterLeft }}) </span>
                <span style="color: red; margin-left: 0.2em;" v-else>Warning: No air filter installed!</span>
            </v-list-item>
			<v-list-item>Heat: {{ heat }}%</v-list-item>
            <v-list-item>Power: {{ power }}%</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
import moment from 'moment';

export default {
    name: 'VitalSystemsCard',
    props: {
        entity: null
    },
    computed: {
        lifeSupport() {
            return this.entity.lifeSupport;
        },
        oxygen() {
            return (this.lifeSupport.oxygen / this.lifeSupport.maxOxygen * 100).toFixed(0);
        },
        airFilterLeft() {
            let timeLeft = this.lifeSupport.getAirFilterTimeLeft();
            if(timeLeft) {
                return moment().startOf('day')
                    .seconds(timeLeft)
                    .format('mm:ss');
            } else {
                return null;
            }
        },
        heat() {
            return (this.lifeSupport.heat / this.lifeSupport.maxHeat * 100).toFixed(0);
        },

        generator() {
            return this.entity.generator;
        },
        power() {
            return (this.generator.power / this.generator.maxPower * 100).toFixed(0);
        }
    }
};
</script>