<template>
  <div class="game">
    <div id="game-wrapper" v-if="downloaded">
      <phaser-canvas></phaser-canvas>
      <HUD ref="hud"></HUD>
    </div>
    <div class="placeholder" v-else>
      Downloading ...
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PhaserCanvas from '@/components/game/PhaserCanvas'
import HUD from '@/components/game/HUD'

export default {
  name: 'game',
  data() {
    return {
      downloaded: false
    }
  },
  methods: {
    onWindowResize() {
      var container = document.getElementById('game-container');
      this.game.scale.resize(container.scrollWidth, container.scrollHeight);
    }
  },
  components: {
    PhaserCanvas,
    HUD
  },
  mounted() {
    if(this.$ga) {
      this.$ga.page('/');
    }

    import(/* webpackChunkName: "game" */ '@/game/game').then(gameInit => {
      this.downloaded = true;
      this.$nextTick(() => {
        var game = gameInit.launch();
        game.hud = this.$refs.hud;
        this.game = game;

        window.addEventListener('resize', () => {
          this.onWindowResize();
        });
      });
    });
  },
  beforeDestroy() {
    if(this.game) {
      this.game.destroy();
      delete this.game;
    }
  }
}
</script>

<style>
  .game {
    display: flex;
    flex: 1;
    min-height: 0;
    position: relative;
  }

  #game-wrapper {
    width: 100%;
    height: 100%;
  }
  .placeholder {
    font-size: 2rem;
    font-family: 'Courier New', Courier, monospace;
  }
</style>
