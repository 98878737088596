<template>
	<div class="loading-menu">
		<v-progress-circular v-model="loading" color="white" size="72" width="12">{{ loading }}%</v-progress-circular>
		<p/>
		<div style="color: white">Loading...</div>
	</div>
</template>


<script>
export default {
	name: 'LoadingMenu',
	props: {
		loading: null
	}
}
</script>

<style scoped>
	.loading-menu {
		position: absolute;
		top: 30%;
		left: 50%;
		transform: translateX(-50%);
	}
</style>