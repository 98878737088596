<template>
  <dialog-drag :title="ship.name" :options="options" @close="close" class="ship-info-dialog">
    <div v-on:mouseup.stop>
      <v-tabs
        v-model="activeTab"
        grow>
          
        <v-tab v-for="tab in tabs" :key="tab.name">{{ tab.name }}</v-tab>

        <v-tab-item v-for="tab in tabs" :key="tab.name">
          <v-container grid-list-md v-on:mouseup.stop>
            <v-layout row wrap>
              <v-flex xs12>
                <component :is="tab.component" :entity="ship" v-if="tab.component" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </div>
  </dialog-drag>
</template>


<script>
import CargoListPanel from '@/components/game/panels/CargoListPanel';
import CrewListPanel from '@/components/game/panels/CrewListPanel';
import ShipOverviewPanel from '@/components/game/panels/ShipOverviewPanel';

export default {
  name: 'ShipInfo',
  props: {
    ship: {
      default: null
    }
  },
  data() {
    return {
      activeTab: null
    };
  },
  computed: {
    options() {
      var left = window.innerWidth / 4;
      var top = window.innerHeight / 6;
      if(window.innerWidth < 900) {
        left = window.innerWidth / 20;
        top = window.innerHeight / 12;
      }

      return {
        buttonPin: false,
        left: left,
        top: top
      };
    },
    tabs() {
      return [
        {
          name: 'Info',
          component: ShipOverviewPanel
        },
        {
          name: 'Crew',
          component: CrewListPanel
        },
        {
          name: 'Equipment',
          component: null
        },
        {
          name: 'Cargo Bay',
          component: CargoListPanel
        }
      ];
    }
  },
  methods: {
    close() {
      this.$emit('deleteData', 'shipInfo');
    }
  },
  components: {
    CargoListPanel,
    ShipOverviewPanel
  }
}
</script>

<style scoped>
  .ship-info-dialog {
    width: 50vw;
  }

  @media only screen and (max-width: 900px) {
    .ship-info-dialog {
      width: 90vw;
    }
  }
</style>