<template>
    <dialog-drag title="Game Over - You Died" :options="options" class="game-menu-dialog">
        <v-container grid-list-md>
            <v-layout row wrap>
                <v-flex xs12 v-if="continueConfig">
                    <v-btn @click="load">Reload</v-btn>
                </v-flex>

                <v-flex xs12>
                    <v-btn @click="newGame">New Game</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </dialog-drag>
</template>


<script>
import localForage from 'localforage';

export default {
    name: 'GameOverMenu',
    props: {
        time: null
    },
    data() {
        return {
            continueConfig: null
        };
    },
    computed: {
        options() {
        var left = window.innerWidth / 4;
        var top = window.innerHeight / 10;
        if(window.innerWidth < 900) {
            left = window.innerWidth / 20;
            top = window.innerHeight / 20;
        }

        return {
            buttonPin: false,
            buttonClose: false,
            pinned: true,
            left: left,
            top: top
        };
        }
    },
    methods: {
        load() {
            localForage.getItem('main-save').then((config) => {
                this.time.scene.scene.stop('GameOverScene');

                let playScene = this.time.scene.scene.manager.getScene('Universe');
                playScene.config = config;
                this.time.scene.scene.start(playScene);
                this.close();
            });
        },
        newGame() {
            this.time.scene.scene.stop('GameOverScene');

            let solSystem = this.time.scene.scene.manager.getScene('Sol System');
            solSystem.config = solSystem.newGameConfig;
            this.time.scene.scene.start(solSystem);
            this.close();
        },
        close() {
            this.$emit('deleteData', 'gameOverScreen');
        }
    },
    mounted() {
        localForage.getItem('main-save').then((config) => {
            if(config) {
                this.continueConfig = true;
            }
        });
    }
}
</script>

<style scoped>
.game-menu-dialog {
    width: 50vw;
}

@media only screen and (max-width: 900px) {
    .game-menu-dialog {
    width: 90vw;
    }
}
</style>