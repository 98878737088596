<template>
    <dialog-drag title="Universe Info" :options="options" @close="close" class="game-menu-dialog">
        <div v-on:mouseup.stop>
            <v-tabs
                v-model="activeTab"
                grow>
                
                <v-tab v-for="tab in tabs" :key="tab.name">{{ tab.name }}</v-tab>

                <v-tab-item v-for="tab in tabs" :key="tab.name">
                    <v-container grid-list-md v-on:mouseup.stop>
                        <v-layout row wrap>
                            <v-flex xs12>
                                <component :is="tab.component" :time="time" v-if="tab.component" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-tab-item>
            </v-tabs>
        </div>
    </dialog-drag>
</template>


<script>
import GlobalResourcesPanel from '@/components/game/panels/global/GlobalResourcesPanel';
import GlobalShipsPanel from '@/components/game/panels/global/GlobalShipsPanel';
import GlobalStationsPanel from '../panels/global/GlobalStationsPanel';

export default {
    name: 'UniverseInfoMenu',
    props: {
        time: null
    },
    data() {
        return {
            activeTab: null
        };
    },
    computed: {
        options() {
            var left = window.innerWidth / 4;
            var top = window.innerHeight / 6;
            if(window.innerWidth < 900) {
                left = window.innerWidth / 20;
                top = window.innerHeight / 12;
            }

            return {
                buttonPin: false,
                left: left,
                top: top
            };
        },
        tabs() {
            return [
                {
                    name: 'Resources',
                    component: GlobalResourcesPanel
                },
                {
                    name: 'Ships',
                    component: GlobalShipsPanel
                },
                {
                    name: 'Stations',
                    component: GlobalStationsPanel
                }
            ];
        }
    },
    methods: {
        close() {
            this.$emit('deleteData', 'universeInfoScreen');
        }
    },
    components: {
        GlobalResourcesPanel
    }
}
</script>

<style scoped>
    .game-menu-dialog {
        width: 50vw;
    }

    @media only screen and (max-width: 900px) {
        .game-menu-dialog {
            width: 90vw;
        }
    }

    .v-card {
        height: 100%;
    }
</style>