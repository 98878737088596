<template>
	<v-container grid-list-md>
		<v-layout row wrap>
			<v-flex xs6>
				<v-list subheader dense>
					<v-subheader style="text-decoration: underline">Created Products</v-subheader>

					<v-list-item v-for="item in factory.products" :key="item.name">
						<v-list-item-content>
							<v-list-item-title>{{ item.quantity }} {{ item.name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-flex>

			<v-flex xs6>
				<v-list subheader dense>
					<v-subheader style="text-decoration: underline">Used Resources</v-subheader>

					<v-list-item v-for="item in factory.resources" :key="item.name">
						<v-list-item-content>
							<v-list-item-title :style="{color: resourceColor(item)}">{{ item.quantity }} {{ item.name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-flex>

			<v-flex xs12>
				<v-progress-circular v-model="factory.progress" :color="progressColor" size="72" width="12">{{ factory.progress.toFixed(0) }}%</v-progress-circular>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
export default {
	name: 'FactoryPanel',
	props: {
		factory: null
	},
	computed: {
		progressColor() {
			if(this.factory.canProduceProducts()) {
				return 'green';
			} else {
				return 'red';
			}
		}
	},
	methods: {
		resourceColor(resource) {
			if(this.factory.hasEnoughOfResource(resource)) {
				return '';
			} else {
				return 'red';
			}
		}
	}
}
</script>