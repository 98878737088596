<template>
	<div class="player-health-bars">
		<div class="health-bar-wrapper">
            <span class="health-bar-text">Oxygen:</span>
            <v-progress-linear v-model="oxygen" :color="oxygenColor" height="12" />
        </div>

        <div class="health-bar-wrapper">
            <span class="health-bar-text">Heat:</span>
            <v-progress-linear v-model="heat" :color="heatColor" height="12" />
        </div>

        <div class="health-bar-wrapper">
            <span class="health-bar-text">Power:</span>
            <v-progress-linear v-model="power" :color="powerColor" height="12" />
        </div>
	</div>
</template>

<script>
export default {
    name: 'PlayerHealthBars',
    props: {
        playerShip: null
    },
    computed: {
        lifeSupport() {
            return this.playerShip.lifeSupport;
        },
        oxygen() {
            return this.lifeSupport.oxygen / this.lifeSupport.maxOxygen * 100;
        },
        oxygenColor() {
            let timeLeft = this.lifeSupport.airFilter?.currentLife;

            if(timeLeft) {
                return 'white';
            } else {
                return 'red';
            }
        },
        heat() {
            return this.lifeSupport.heat / this.lifeSupport.maxHeat * 100;
        },
        heatColor() {
            let heat = this.heat;
            if(heat > 90) {
                return 'red';
            } else if(heat > 50) {
                return 'yellow';
            } else {
                return 'green';
            }
        },

        generator() {
            return this.playerShip.generator;
        },
        power() {
            return this.generator.power / this.generator.maxPower * 100;
        },
        powerColor() {
            let power = this.power;
            if(power > 50) {
                return 'green';
            } else if(power > 10) {
                return 'yellow';
            } else {
                return 'red';
            }
        }
    }
};
</script>

<style scoped>
.player-health-bars {
    position: absolute;
    right: 1em;
    top: 1em;
    width: 14vw;
}

.health-bar-wrapper {
    display: flex;
    align-items: center;
    align-content: center;
}

.health-bar-text {
    color: white;
    margin-right: 0.5em;
}
</style>