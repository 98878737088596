<template>
	<v-list>
		<template v-for="(crew, index) in livingQuarters.crew">
			<v-divider :key="index" v-if="index > 0" />

			<v-list-item :key="crew.name">
				<v-list-item-content>
					<v-list-item-title>{{ crew.name }}</v-list-item-title>
					<v-list-item-subtitle>{{ crew.title }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</template>
	</v-list>
</template>

<script>
export default {
	name: 'CrewListPanel',
	props: {
		entity: null
	},
	computed: {
		livingQuarters() {
			return this.entity.livingQuarters;
		}
	}
}
</script>