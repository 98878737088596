import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import DialogDrag from 'vue-dialog-drag';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import VueAnalytics from 'vue-analytics';

import 'vue-dialog-drag/dist/vue-dialog-drag.css';

window.Vue = Vue;
Vue.config.productionTip = false;
Vue.use(Vuetify);
Vue.component('dialog-drag', DialogDrag);

let vuetify = new Vuetify({
  icons: {
    iconfont: 'md'
  }
});

if(process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID
  });
}

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
