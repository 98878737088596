<template>
    <div>
        <vital-systems-card :entity="entity" />
    </div>
</template>

<script>
import VitalSystemsCard from './VitalSystemsCard';

export default {
    name: 'ShipOverviewPanel',
    props: {
        entity: null
    },
    computed: {
        lifeSupport() {
            return this.entity.lifeSupport;
        },
        oxygen() {
            return (this.lifeSupport.oxygen / this.lifeSupport.maxOxygen * 100).toFixed(0);
        },
        heat() {
            return (this.lifeSupport.heat / this.lifeSupport.maxHeat * 100).toFixed(0);
        }
    },
    components: {
        VitalSystemsCard
    }
};
</script>