<template>
  <dialog-drag :title="station.name" :options="options" class="docked-in-station-dialog">
    <v-container grid-list-md v-on:mouseup.stop>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title primary-title style="padding-bottom: 0">
              <div class="headline">Factory</div>
            </v-card-title>

            <factory-panel :factory="station.factory" />
          </v-card>
        </v-flex>

        <v-flex xs12 md6>
          <v-card class="cargo-bay-panel-card">
            <v-card-title primary-title>
              <div class="headline">Ship's Cargo</div>
            </v-card-title>
            <cargo-list-panel :entity="playerShip" :linked-cargo-bay="station.cargoBay" />
          </v-card>
        </v-flex>
        <v-flex xs12 md6>
          <v-card class="cargo-bay-panel-card">
            <v-card-title primary-title>
              <div class="headline">Station's Cargo</div>
            </v-card-title>
            <cargo-list-panel :entity="station" :linked-cargo-bay="playerShip.cargoBay" />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-btn @click="undock">Undock</v-btn>
  </dialog-drag>
</template>


<script>
import CargoListPanel from '@/components/game/panels/CargoListPanel';
import FactoryPanel from '@/components/game/panels/FactoryPanel';

export default {
  name: 'DockedInStation',
  props: {
    playerShip: {
      default: null
    },
    station: {
      default: null
    }
  },
  computed: {
    options() {
      var left = window.innerWidth / 4;
      var top = window.innerHeight / 10;
      if(window.innerWidth < 900) {
        left = window.innerWidth / 20;
        top = window.innerHeight / 20;
      }

      return {
        buttonPin: false,
        buttonClose: false,
        pinned: true,
        left: left,
        top: top
      };
    }
  },
  methods: {
    undock() {
      this.close();
    },
    close() {
      this.$emit('deleteData', 'dockedInStation');
    }
  },
  beforeDestroy() {
    this.station.dock.undockShip(this.playerShip);
  },
  components: {
    CargoListPanel,
    FactoryPanel
  }
}
</script>

<style scoped>
  .docked-in-station-dialog {
    width: 50vw;
  }

  @media only screen and (max-width: 900px) {
    .docked-in-station-dialog {
      width: 90vw;
    }
  }

  .cargo-bay-panel-card {
    height: 100%;
  }
</style>