<template>
  <dialog-drag :title="station.name" :options="options" @close="close" class="station-info-dialog">
    <div v-on:mouseup.stop>
      <v-tabs
        v-model="activeTab"
        grow>
          
        <v-tab v-for="tab in tabs" :key="tab.name">{{ tab.name }}</v-tab>

        <v-tab-item v-for="tab in tabs" :key="tab.name">
          <v-container grid-list-md v-on:mouseup.stop>
            <v-layout row wrap>
              <v-flex xs12>
                <component :is="tab.component" :entity="station" v-if="tab.component" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </div>
  </dialog-drag>
</template>


<script>
import CargoListPanel from '@/components/game/panels/CargoListPanel';
import CrewListPanel from '@/components/game/panels/CrewListPanel';
import DockedListPanel from '@/components/game/panels/DockedListPanel';
import StationOverviewPanel from '@/components/game/panels/StationOverviewPanel';

export default {
  name: 'StationInfo',
  props: {
    station: {
      default: null
    }
  },
  data() {
    return {
      activeTab: null
    };
  },
  computed: {
    options() {
      var left = window.innerWidth / 4;
      var top = window.innerHeight / 10;
      if(window.innerWidth < 900) {
        left = window.innerWidth / 20;
        top = window.innerHeight / 12;
      }

      return {
        buttonPin: false,
        left: left,
        top: top
      };
    },
    tabs() {
      return [
        {
          name: 'Info',
          component: StationOverviewPanel
        },
        {
          name: 'Crew',
          component: CrewListPanel
        },
        {
          name: 'Cargo Bay',
          component: CargoListPanel
        },
        {
          name: 'Docked Ships',
          component: DockedListPanel
        }
      ];
    }
  },
  methods: {
    close() {
      this.$emit('deleteData', 'stationInfo');
    }
  },
  components: {
    CargoListPanel,
    DockedListPanel,
    StationOverviewPanel
  }
}
</script>

<style scoped>
  .station-info-dialog {
    width: 50vw;
  }

  @media only screen and (max-width: 900px) {
    .station-info-dialog {
      width: 90vw;
    }
  }

  .v-card {
    height: 100%;
  }
</style>