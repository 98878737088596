<template>
    <v-data-table
        :headers="headers"
        :items="resources"
        class="elevation-1"
        >

        <template v-slot:item="props">
            <tr>
                <td class="text-left">{{ props.item.name }}</td>
                <td class="text-left">{{ props.item.quantity }}</td>
                <td class="text-left">{{ (props.item.demand * 60).toFixed(1) }} / min</td>
                <td class="text-left">{{ (props.item.production * 60).toFixed(1) }} / min</td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>

export default {
    props: {
        time: null
    },
    name: 'GlobalResourcesPanel',
    data() {
        return {
            headers: [
                { text: 'Item', value: 'name' },
                { text: 'Quantity', value: 'quantity' },
                { text: 'Demand', value: 'demand' },
                { text: 'Production', value: 'production' }
            ]
        };
    },
    computed: {
        entities() {
            return this.time.scene.getAllEntities();
        },
        resources() {
            let resourceMap = {};

            this.entities.forEach((entity) => {
                if(!entity.cargoBay) {
                    return;
                }

                entity.cargoBay.items.forEach((item) => {
                    getResource(resourceMap, item.name).quantity += item.quantity;
                });

                if(entity.factory) {
                    entity.factory.products.forEach((product) => {
                        getResource(resourceMap, product.name).production += product.quantity / entity.factory.requiredProductionTime;
                    });

                    entity.factory.resources.forEach((resource) => {
                        getResource(resourceMap, resource.name).demand += resource.quantity / entity.factory.requiredProductionTime;
                    });
                }

                if(entity.lifeSupport) {
                    // 100 is the default life capacity of an air filter
                    getResource(resourceMap, 'Air Filter').demand += entity.lifeSupport.airRequired() / 100;
                }
            });

            let resources = [];
            for(var name in resourceMap) {
                resources.push(resourceMap[name]);
            }

            resources.sort((a, b) => {
                if(a.name > b.name) {
                    return 1;
                } else {
                    return -1;
                }
            });

            return resources;
        }
    }
};

function getResource(resourceMap, name) {
    if(!resourceMap[name]) {
        resourceMap[name] = {
            name: name,
            quantity: 0,
            demand: 0,
            production: 0
        };
    }

    return resourceMap[name];
}
</script>