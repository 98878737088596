<template>
  <dialog-drag title="Game Menu" :options="options" class="game-menu-dialog" @close="close">
    <v-container grid-list-md v-on:mouseup.stop>
      <v-layout row wrap>
        <v-flex xs12>
          <v-btn @click="save">Save</v-btn>
        </v-flex>

        <v-flex xs12>
          <v-btn @click="load">Load</v-btn>
        </v-flex>

        <v-flex xs12>
          <v-btn @click="close">Resume</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </dialog-drag>
</template>


<script>
import localForage from 'localforage';

export default {
  name: 'DockedInStation',
  props: {
    time: null
  },
  computed: {
    options() {
      var left = window.innerWidth / 4;
      var top = window.innerHeight / 10;
      if(window.innerWidth < 900) {
        left = window.innerWidth / 20;
        top = window.innerHeight / 20;
      }

      return {
        buttonPin: false,
        buttonClose: true,
        pinned: true,
        left: left,
        top: top
      };
    }
  },
  methods: {
    save() {
       var config = this.time.scene.saveConfig();
       localForage.setItem('main-save', config).then(() => {
         this.close();
       });
    },
    load() {
      localForage.getItem('main-save').then((config) => {
        this.time.scene.loadConfig(config);
        this.close();
      });
    },
    close() {
      this.time.paused = !this.time.paused;
    }
  }
}
</script>

<style scoped>
  .game-menu-dialog {
    width: 50vw;
  }

  @media only screen and (max-width: 900px) {
    .game-menu-dialog {
      width: 90vw;
    }
  }
</style>