<template>
    <v-data-table
        :headers="headers"
        :items="stations"
        class="elevation-1"
        >

        <template v-slot:item="props">
            <tr>
                <td class="text-left">{{ props.item.type }}</td>
                <td class="text-left">{{ props.item.quantity }}</td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>

export default {
    props: {
        time: null
    },
    name: 'GlobalStationsPanel',
    data() {
        return {
            headers: [
                { text: 'Type', value: 'type' },
                { text: 'Quantity', value: 'quantity' }
            ]
        };
    },
    computed: {
        stations() {
            let stations = this.time.scene.getAllStations();
            let workingStations = stations.filter((station) => {
                return station.factory.canProduceProducts();
            });
            let deadStations = stations.filter((station) => {
                return !station.livingQuarters.crew.length;
            });
            let needsResourcesStations = stations.filter((station) => {
                return !station.factory.hasEnoughOfResources() && !station.livingQuarters.isCrewDead();
            });

            return [
                {
                    type: 'Working',
                    quantity: workingStations.length
                },
                {
                    type: 'Dead',
                    quantity: deadStations.length
                },
                {
                    type: 'Needs Resources',
                    quantity: needsResourcesStations.length
                }
            ];
        }
    }
};
</script>