<template>
    <div>
        <v-card>
            <v-card-title primary-title>
                <div class="headline">Factory</div>
            </v-card-title>

            <factory-panel :factory="entity.factory" />
        </v-card>

        <vital-systems-card :entity="entity" />
    </div>
</template>

<script>
import FactoryPanel from '@/components/game/panels/FactoryPanel';
import VitalSystemsCard from './VitalSystemsCard';

export default {
    name: 'StationOverviewPanel',
    props: {
        entity: null
    },
    computed: {
        lifeSupport() {
            return this.entity.lifeSupport;
        },
        oxygen() {
            return (this.lifeSupport.oxygen / this.lifeSupport.maxOxygen * 100).toFixed(0);
        },
        heat() {
            return (this.lifeSupport.heat / this.lifeSupport.maxHeat * 100).toFixed(0);
        }
    },
    components: {
        FactoryPanel,
        VitalSystemsCard
    }
};
</script>