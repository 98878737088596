<template>
	<div id="game-hud">
		<loading-menu v-if="Number.isInteger(loading)" :loading="loading" />
		<title-menu v-if="titleScreen" @deleteData="deleteData" :time="time" />
		<game-over-menu v-if="gameOverScreen" @deleteData="deleteData" :time="time" />

		<player-health-bars :player-ship="playerShip" v-if="playerShip" />

		<station-info :station="stationInfo" v-if="stationInfo" @deleteData="deleteData"></station-info>
		<ship-info :ship="shipInfo" v-if="shipInfo" @deleteData="deleteData"></ship-info>
		<docked-in-station :player-ship="playerShip" :station="dockedInStation" v-if="dockedInStation" @deleteData="deleteData"></docked-in-station>

		<universe-info-menu v-if="universeInfoScreen" @deleteData="deleteData" :time="time" />

		<game-menu v-if="time.paused" :time="time" />
		<v-alert :value="time.paused" color="blue" class="paused-alert">Game is paused!</v-alert>
	</div>
</template>


<script>
import LoadingMenu from './menus/LoadingMenu';
import TitleMenu from './menus/TitleMenu';
import GameOverMenu from './menus/GameOverMenu';

import PlayerHealthBars from './hud/PlayerHealthBars';

import StationInfo from './menus/StationInfo';
import ShipInfo from './menus/ShipInfo';
import DockedInStation from './menus/DockedInStation';

import UniverseInfoMenu from './menus/UniverseInfoMenu';

import GameMenu from './menus/GameMenu';

export default {
	name: 'HUD',
	data: function() {
		return {
			loading: null,
			titleScreen: false,
			gameOverScreen: false,
			time: {
				paused: false
			},

			playerShip: false,
			stationInfo: false,
			shipInfo: false,
			dockedInStation: false,

			universeInfoScreen: false
		}
	},
	methods: {
		escape: function() {
			// See if there is any menus to close
			for(var name in this._data) {
				if(['time', 'playerShip'].includes(name)) {
					continue;
				}

				if(this._data[name]) {
					this[name] = null;
					return;
				}
			}

			this.time.paused = !this.time.paused;
		},
		deleteData: function(name) {
			this[name] = null;
		}
	},
	watch: {
		gameOverScreen(value) {
			if(value) {
				for(var name in this._data) {
					if(['time'].includes(name)) {
						continue;
					}

					if(this._data[name]) {
						this[name] = null;
					}
				}
			}

			this.gameOverScreen = value;
		}
	},
	mounted: function() {
		window.mainGameHUD = this;
	},
	components: {
		LoadingMenu,
		TitleMenu,
		GameOverMenu,

		PlayerHealthBars,

		StationInfo,
		ShipInfo,
		DockedInStation,

		UniverseInfoMenu,

		GameMenu
	}
}
</script>


<style scoped>
#game-hud {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	overflow: auto;
}

.paused-alert {
	position: absolute;
	width: 100%;
	bottom: 0;
}
</style>